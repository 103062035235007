import * as React from 'react';
// importを追記
import { useStaticQuery, graphql, Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import Moment from 'react-moment';
// componentsを追記
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import Pagenation from '../../components/pagenation';

const Index = ({ data, location, pageContext }) => {
const posts = data.allContentfulBlogPost.edges;
return (
<Layout>
  {/* meta要素 */}
  {/* (NULLの場合はgatsby-config.jsのsiteMetadataを使用する) */}
  <SEO
    title="TUREDUREGUSA, Modern translation"
    description=""
    keyword=""
    site_name=""
    url=""
    og_title=""
    og_type=""
    og_image=""
    tw_cardType=""
    tw_userName=""
  />
  {/* html要素 */}
  <div className="g-container">
    <main className="g-pageMain" role="main">
      <div className="l-container">
        <div className="u-mt-x5">
          <h1 className="c-headingLv1">TUREDUREGUSA, Modern translation</h1>
          <p className="c-text">Let's learn TUREDUREGUSA.<br />Click the title to read the modern translation.</p>
          <p className="c-text"><Link to={`/en/about/`}>What kind of person is Yoshida Kenko?</Link></p>
          <p className="c-text"><Link to="/">Switch Japanese</Link></p>
          <ul className="c-news">
          {console.log(posts)}
          {posts && posts.map(({node: post}, key) => {
            return (
              <li className="c-news_item" key={key}>
                <Link to={`/en/post/${post.slug}/`} className="c-news_itemLink">
                  <Moment format="MM/DD/YYYY" className="c-news_itemDate">
                    {post.publishDate}
                  </Moment>
                  <span className="c-news_itemContent">
                    <strong>{post.title}: </strong>(Original) 
                    {post.body.body.substr(0, 40) + '...'}
                  </span>
                </Link>
              </li>
            );
          })}
          </ul>
          <div className="u-mt-x5">
            <Pagenation pageContext={pageContext} />
          </div>
          <p className="c-text"><Link to={`/en/page/`}>About this website</Link></p>
        </div>
        <div className="u-mt-x5 u-ta-right">
          <p className="c-pageTop" data-js-localscroll>
            <a href="#top">
              <img
                className="i-label i-label-left"
                src="/assets/img/icons/ic_tri_up.svg"
                alt=""
              />
              Page top
            </a>
          </p>
        </div>
      </div>
      {/* /.l-container */}
    </main>
    {/* /.g-pageMain */}
  </div>
  {/* /.g-container */}
</Layout>
);
};
export default Index;

export const query = graphql`
query turezureEnQuery($skip: Int!, $limit: Int!) {
  allContentfulBlogPost(
    sort: { order: ASC, fields: publishDate }
    filter: {
      node_locale: { eq: "en-US" }
    }
    skip: $skip
    limit: $limit
  ) {
    edges {
      node {
        id
        title
        slug
        node_locale
        body {
          body
        }
        description {
          description
        }
        publishDate(fromNow: false, locale: "", formatString: "")
        author {
          id
          name
          twitter
          title
          phone
          node_locale
          email
          company
          github
          facebook
          childContentfulPersonShortBioTextNode {
            shortBio
          }
        }
      }
    }
    totalCount
    pageInfo {
      perPage
    }
  }
}
`;
